"use strict";
/*!
 *
 *  header.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$header = $('#HeaderFollow');
        this.$page2top = $('#Page2Top');
        this.isTop = $('#Header').is('.-top');
        this.position = this.getPosition();
        this.$header = this.buildHeader();
        $(window).on('resize', function (e) {
            _this.position = _this.getPosition();
        });
        $(window).on('scroll', function (e) {
            if (!_this.$header.is('.-visible') && $(window).scrollTop() > _this.position) {
                _this.$header.addClass('-visible');
                _this.$page2top.addClass('-visible');
            }
            ;
            if (_this.$header.is('.-visible') && $(window).scrollTop() <= _this.position) {
                _this.$header.removeClass('-visible');
                _this.$page2top.removeClass('-visible');
            }
            ;
        });
    }
    default_1.prototype.buildHeader = function () {
        var $clone = $('#Header').children().clone();
        var $follow = $('<div class="st-Header -follow" id="HeaderFollow" />');
        $follow.append($clone);
        $('body').append($follow);
        return $follow;
    };
    ;
    default_1.prototype.getPosition = function () {
        if (this.isTop) {
            return $('#Hero').offset().top + $('#Hero').height();
        }
        else {
            return $('.st-Main_Inner').offset().top;
        }
        ;
    };
    return default_1;
}());
exports.default = default_1;
;
