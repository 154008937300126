"use strict";
/*!
 *
 *  expander.js
 *
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var toggler_1 = require("toggler");
var Expander = /** @class */ (function (_super) {
    __extends(Expander, _super);
    function Expander(elem, option) {
        var _this = _super.call(this, elem, option) || this;
        _this.$head = _this.$elem.find(_this.setting.head);
        return _this;
    }
    Expander.prototype.expand = function (e) {
        var _this = this;
        if (this.setting.viewport[util.viewport]) {
            e.preventDefault();
            var $this = $(e.currentTarget);
            var $target = this.getTarget($this);
            if ($this.is('.-expanded')) {
                $this.removeClass('-expanded');
                $target.stop(true, false).animate({
                    height: 'hide'
                }, 700, 'easeOutExpo', function () {
                    $(_this).removeClass('-expanded');
                });
            }
            else {
                if (this.setting.autoClose) {
                    this.reset();
                }
                ;
                $this.addClass('-expanded');
                $target.stop(true, false).animate({
                    height: 'show'
                }, 700, 'easeOutExpo', function () {
                    $(_this).addClass('-expanded');
                });
            }
            ;
        }
        ;
    };
    Expander.prototype.reset = function () {
        var _this = this;
        this.$head.filter(function (i, elem) {
            return $(elem).is('.-expanded');
        }).removeClass('-expanded');
        this.$body.filter(function (i, elem) {
            return $(elem).is('.-expanded');
        }).stop(true, false).animate({
            height: 'hide'
        }, 700, 'easeOutExpo', function () {
            $(_this).removeClass('-expanded');
        });
    };
    Expander.prototype.setEvent = function () {
        var _this = this;
        _super.prototype.setEvent.call(this);
        $(window).on('viewportChanged', function () {
            _this.reset();
        });
        this.$elem.on('close', function () {
            var self = _this;
            _this.$head.removeClass('-expanded');
            _this.$body.removeClass('-expanded')
                .stop(true, false).animate({
                height: 'hide'
            }, 700, 'easeOutExpo');
        });
    };
    return Expander;
}(toggler_1.Toggler));
$.fn.expander = function (option) {
    return this.each(function (i, elem) {
        new Expander(elem, option ? option : {});
    });
};
