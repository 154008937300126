"use strict";
/*!
 *
 *  page2top.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$elem = $('#Page2Top');
        this.$footer = $('.st-Siteinfo');
        this.isStatic = false;
        this.borderLine = 0;
        this.borderLine = this.setBorder();
        $(window).on('resize', function (e) {
            _this.borderLine = _this.setBorder();
        });
        $(window).on('scroll', function (e) {
            var _bottom = $(e.currentTarget).scrollTop() + util.wh - (util.viewport !== 'phone' ? 30 : 20);
            if (!_this.isStatic && _bottom >= _this.borderLine) {
                _this.isStatic = true;
                _this.$elem.addClass('-static');
            }
            ;
            if (_this.isStatic && _bottom < _this.borderLine) {
                _this.isStatic = false;
                _this.$elem.removeClass('-static');
            }
        });
        $(window).on('load', function () {
            _this.borderLine = _this.setBorder();
        });
    }
    default_1.prototype.setBorder = function () {
        return this.$footer.offset().top + (this.$elem.children().outerHeight() / 2);
    };
    return default_1;
}());
exports.default = default_1;
