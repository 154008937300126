"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$btn = $('.st-Menu_Btn');
        this.top = 0;
        this.$btn.on('click', function (e) {
            e.preventDefault();
            var $inner = $(e.currentTarget).closest('.st-Header_Inner');
            if ($inner.is('.-menu-expanded')) {
                $inner.removeClass('-menu-expanded');
                $('html').css({
                    overflow: ''
                });
                document.removeEventListener('touchmove', _this.scrollControll);
            }
            else {
                $inner.addClass('-menu-expanded');
                $('html').css({
                    overflow: 'hidden'
                });
                document.addEventListener('touchmove', _this.scrollControll, {
                    passive: false
                });
            }
            ;
        });
    }
    default_1.prototype.scrollControll = function (e) {
        var $inner = $(e.target).closest('.st-Menu_Main_Body_Inner');
        if ($inner.length > 0) {
            if ($inner.get(0).scrollHeight > $inner.innerHeight()) {
                e.stopPropagation();
            }
            else {
                e.preventDefault();
            }
            ;
        }
        else {
            e.preventDefault();
        }
    };
    return default_1;
}());
exports.default = default_1;
;
